

export const GrowSpinner = props => {

    const {column, text_info, subtext, color, display} = props

    return(
        // eslint-disable-next-line
        <div className={column, display}>
        <p>{text_info}</p>
        <p>{subtext}</p>
        <div className={`spinner-grow text-${color}`} role="status">
            <span className="visually-hidden">Loading...</span>
        </div>
      </div> 
    )
}