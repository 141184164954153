

export const Nav = () => {

    return(
        <header className="p-3 bg-dark text-white ">
            <div className="container ">
              <div className="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
                <div className="text-end">
                  <h3 className="fw-light">Bienvenido a TipBot</h3>
                </div>
              </div>
            </div>
        </header>
    )
}