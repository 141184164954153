import { Home } from './views/Home'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

function App() {



//comentario 
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home/>}/>
      </Routes>
    </Router>
  );
}   

export default App;
