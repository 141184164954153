import { Fragment, useState } from "react"
import { Nav } from "./Nav"
import { GrowSpinner } from "./GrowSpinner"
import axios from 'axios'

export const Home = () => {
  /* State de las props del componente grow_spinner, manipuladas dinamicamente en este componente padre */
  const [loader, set_loader] = useState({
    column: 'col-12',
    text_info: '',
    subtext: '',
    color: '',
    display: 'd-none'
  })
  /* Destructuracion del state */
  const {column, text_info, subtext, color, display} = loader;

  /* State de la informacion de las clases */
  const [meetings, set_meetings] = useState({
    data: [],
    ready: false
  })
  /* Manipulacion del state de props del componente grow_spinner */
  const start_progress = () => {
    set_loader({
      ...loader,
      display: '',
      color: 'warning',
      text_info: 'Cargando credenciales...',
      subtext: 'Autorizar permisos a navegador emergente...'
    })
  }

  /*Inicio del proceso de webscraping, consumiendo servicios del backend y manipulando el loader  */
  const start_scraping = async() => {
    start_progress()
    /* Obtencion de cookies para continuar con el proceso headless */
    await axios.get('https://tipzoombot.iapp.cl/app/cookie')
    .then(res => {
      set_loader({
        ...loader,
        text_info: 'Credenciales cargadas correctamente...',
        subtext: 'Proceso de publicacion de clases en curso...',
        color: 'primary',
        display: ''
      })
      console.log(res.data)  
    }).catch(e => {
      set_loader({
        ...loader,
        color: 'danger',
        text_info: 'Error en el servicio...',
        subtext: 'Refresque la pagina e intentelo nuevamente...'
      })
      console.log(e)
    })
    /* Comienzo del proceso de publicacion de clases de manera headless */
    await axios.get('https://tipzoombot.iapp.cl/app/scrape')
    .then((res) => {
        console.log(res.data)
        set_meetings({
          data: res.data,
          ready: true
        })
        set_loader({
          ...loader,
          display: 'd-none'
        })
    }).catch(e => {
      console.log(e)
      set_loader({
        ...loader,
        text_info: 'Error en el servicio...',
        subtext: 'Refresque la pagina e intentelo nuevamente...',
        display: '',
        color: 'danger'
      })
    })
  }


  return(
  <Fragment>
  <Nav/>
  <div className="bg-dark text-secondary px-4 py-5 text-center header">
      <div className="py-5">
        <h1 className="display-5 fw-bold text-white">Web Scraping by Tipddy</h1>
        <div className="col-lg-6 mx-auto">
          <p className="fs-5 mb-4">Automatizacion de procesos para la optimizacion de tu organizacion, dedica tu tiempo en lo que sabes hacer y deja que nuestra tecnologia se ocupe del resto</p>
          <div className="d-grid gap-2 d-sm-flex justify-content-sm-center">
            <button onClick={start_scraping} id="lets_go" type="button" className="btn btn-warning btn-lg px-4 me-sm-3">Let's Start Scraping</button>
          </div>
        </div>
      </div>
      <GrowSpinner 
          column={column}
          color={color}
          text_info={text_info}
          subtext={subtext}
          display={display}
        />
      <div>
          {meetings.data ? meetings.data.map((meet, key) => {
            const {title} = meet
            return(
              <p key={key}>{title}</p>
              )
            }) : ''}
      </div>
  </div>
  </Fragment>
  )
}
